.updated-soon-container {
  background-color: #fff;
  color: #333333;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
}

.updated-soon-illustration {
  margin-bottom: 20px;
}

.updated-soon-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
}

.updated-soon-title {
  font-size: 3em;
  color: #d8bf68;
  margin-bottom: 20px;
}

.updated-soon-text {
  font-size: 1.2em;
  color: #6c757d; /* Slate Gray text */
  margin-bottom: 10px;
}

.updated-soon-button {
  background-color: #d8bfd8; /* Dusty Rose for button */
  color: #faf9f6; /* Soft Ivory text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.updated-soon-button:hover {
  background-color: #c6a4c6; /* Slightly darker Dusty Rose */
}

/* Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Media Queries */
/* Large screens (1280px and below) */
@media (max-width: 1280px) {
  .updated-soon-title {
    font-size: 3.5em;
  }

  .updated-soon-text {
    font-size: 1.1em;
  }

  .updated-soon-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

/* Medium screens (1024px and below) */
@media (max-width: 1024px) {
  ::selection {
    background-color: transparent;
  }

  .updated-soon-title {
    font-size: 3em;
  }

  .updated-soon-text {
    font-size: 1em;
  }

  .updated-soon-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

/* Tablets (820px and below) */
@media (max-width: 820px) {
  .updated-soon-container {
    width: 92%;
    height: 78vh;
  }

  .updated-soon-img {
    margin-top: 8%;
    min-width: 95%;
  }

  .updated-soon-title {
    font-size: 2.8em;
    margin-top: 10%;
  }

  .updated-soon-text {
    font-size: 1.5em;
    margin-top: 5%;
  }

  .updated-soon-button {
    font-size: 1.25em;
    padding: 8px 14px;
    margin-top: 5%;
  }
}

/* Tablet devices (768px and below) */
@media (max-width: 768px) {
  .updated-soon-container {
    width: 90%;
    height: 77vh;
  }

  .updated-soon-img {
    margin-top: 8%;
    min-width: 95%;
  }

  .updated-soon-title {
    font-size: 2.5em;
    margin-top: 7%;
  }

  .updated-soon-text {
    font-size: 1.5em;
    margin-top: 5%;
  }

  .updated-soon-button {
    font-size: 1.35em;
    padding: 8px 14px;
    margin-top: 4%;
  }
}

/* Mobile devices (480px and below) */
@media (max-width: 480px) {
  .updated-soon-container {
    width: 81%;
    height: 74vh;
    margin-top: 17%;
    margin-bottom: -25%;
  }

  .updated-soon-img {
    margin-top: 15%;
    min-width: 100%;
  }

  .updated-soon-title {
    font-size: 2.5em;
    margin-top: 10%;
  }

  .updated-soon-text {
    font-size: 1.25em;
    margin-top: 10%;
  }

  .updated-soon-button {
    font-size: 1.35em;
    padding: 10px 14px;
    margin-top: 9%;
  }
}

@media (max-width: 400px) {
  .updated-soon-container {
    width: 81%;
    height: 74vh;
    margin-top: 17%;
    margin-bottom: -25%;
  }

  .updated-soon-img {
    margin-top: -6%;
    min-width: 100%;
  }
}
