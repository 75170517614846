/* General container styling */
.team-section {
  text-align: center;
  margin-bottom: 4%;
  margin-top: 7%;
}

.team-heading {
  font-size: 40px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  animation: fadeIn 2s ease-in-out;
}

.team-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 20px;
  background-color: #f0f0f0;
  animation: fadeIn 2s ease-in-out;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  /* padding: 30px; */
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.team-member:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

.profile-image {
  cursor: pointer;
  margin-bottom: 20px;
}

.profile-image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.profile-image img:hover {
  transform: scale(1.1);
}

.member-info {
  text-align: center;
}

.member-name {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.member-title {
  margin: 10px 0;
  font-size: 16px;
  color: #666;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
}

.modal-content img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease-in-out;
}

.modal-image-animation:hover {
  transform: scale(1.05);
}

/* Responsive styles */
@media (max-width: 1280px) {
  .team-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .profile-image img {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 1024px) {
  .team-container {
    grid-template-columns: 1fr 1fr;
  }

  .team-member {
    padding: 20px;
  }

  .profile-image img {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 820px) {
  .team-container {
    grid-template-columns: 1fr;
    padding: 30px;
    margin-bottom: 6%;
  }

  .team-member {
    gap: 15px;
  }

  .profile-image img {
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 768px) {
  .team-container {
    padding: 20px;
  }

  .team-member {
    text-align: center;
  }

  .profile-image img {
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .team-heading {
    font-size: 40px;
    font-weight: bold;
    color: #333;
    margin-bottom: 35px;
    animation: fadeIn 2s ease-in-out;
  }

  .team-container {
    grid-template-columns: 1fr;
    padding: 15px;
    margin-bottom: 20%;
    margin-top: -7%;
  }

  .team-member {
    width: 90%;
    align-items: center;
    justify-content: center;
    height: 70%;
  }

  .profile-image img {
    width: 80px;
    height: 80px;
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
