/* src/index.css or src/App.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  text-align: center;
  /* background-color: #faf9f6; Soft ivory background */
  background-color: #fff;
  color: #333333; /* Charcoal gray text */
}


a {
  color: #d8bfd8; /* Dusty rose for links and buttons */
}

a:hover {
  color: #b0e0e6; /* Powder blue for hover effect */
}

button {
  background-color: #d8bfd8; /* Dusty rose for buttons */
  border: none;
  color: #ffffff; /* White text on buttons */
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #b0e0e6; /* Powder blue for hover effect */
}

.warning {
  background-color: #f3e5ab; /* Pale gold for warnings */
  color: #b71c1c; /* Darker red for text */
  padding: 10px;
  margin: 10px 0;
}

.success {
  background-color: #d0e3d8; /* Soft sage for positive messages */
  color: #388e3c; /* Darker green for text */
  padding: 10px;
  margin: 10px 0;
}

.border-accent {
  border: 1px solid #fff; /* Light beige for borders and accents */
}

/* Customize the scrollbar for the entire application */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #fff; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #d8bfd8; /* Scrollbar thumb color */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 3px solid #fff; /* Padding around the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c0a0c0; /* Scrollbar thumb color on hover */
}

/* Firefox scrollbar customization */
html {
  scrollbar-width: thin; /* Make the scrollbar thinner */
  scrollbar-color: #d8bfd8 #fff; /* Scrollbar thumb and track colors */
}
