@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.menu-bar-handler {
  /* padding: 20px; */
  text-align: center;
  margin: 5.4% auto auto auto;
  width: 95%;
  margin-top: 6%;
  margin-bottom: -3%;
  font-family: "Inter", sans-serif;
}

.not-found {
  background-color: #fff; /* Soft Ivory background */
  color: #333333; /* Charcoal Gray text */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1.5s ease-in-out;
}

.not-found .illustration {
  margin-bottom: 20px;
}

.not-found h1 {
  font-size: 4em;
  color: #d8bf68; /* Pale Gold for the 404 */
  margin-bottom: 20px;
}

.not-found p {
  font-size: 1.2em;
  color: #6c757d; /* Slate Gray text */
  margin-bottom: 10px;
}

.not-found button {
  background-color: #d8bfd8; /* Dusty Rose for button */
  color: #fff; /* Soft Ivory text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.not-found button:hover {
  background-color: #c6a4c6; /* Slightly darker Dusty Rose */
}

/* Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* media queries */
/* Large screens and desktops */
@media (max-width: 1280px) {
  /* Your styles for 1280px and below */
  .menu-bar-handler {
    margin-top: 8%;
    margin-bottom: -4%;
  }
}

/* Medium screens and small desktops */
@media (max-width: 1024px) {
  /* Your styles for 1024px and below */
  .menu-bar-handler {
    margin-top: 9%;
    margin-bottom: -6%;
  }
}

/* Tablets in portrait mode and small devices */
@media (max-width: 820px) {
  /* Your styles for 820px and below */
  .menu-bar-handler {
    margin-top: 13%;
    margin-bottom: -6%;
  }
}

/* Tablets in portrait mode */
@media (max-width: 768px) {
  /* Your styles for 768px and below */
  .menu-bar-handler {
    margin-top: 14%;
    margin-bottom: -6%;
  }
}

/* Mobile devices */
@media (max-width: 480px) {
  /* Your styles for 480px and below */
  .menu-bar-handler {
    margin-top: 27%;
    margin-bottom: -12%;
  }
}
