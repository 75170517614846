/* Container styling for the home section */
.home-container {
  display: flex;
  width: 98vw;
  height: 90vh;
  margin: 4.6% auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  /* background: #f5f5f0; */
  background: #fff;
  overflow: hidden;
  gap: 5px;
  margin-bottom: 1%;
}

.read-more {
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 10px;
}

.read-more:hover {
  color: #0056b3;
  text-decoration: none;
}

.home-carousel {
  width: 98vw;
  height: 90vh;
  background: #fff;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: relative;
  /* margin: 5.6% auto; */
}

.home-slick-slider.slick-initialized {
  height: 100%;
  background-color: #fff;
}

.home-slick-list {
  height: 100%;
}

.home-slick-track {
  height: 100%;
}

.home-slick-slide {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-carousel-slide {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.home-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: fill; /* Adjusted to maintain aspect ratio */
}

.home-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  overflow: hidden;
}

.slick-prev:before,
.slick-next:before {
  font-size: 24px;
}

.slick-prev {
  left: 10px !important;
  z-index: 1;
  background-color: #3d1e81 !important; /* Bright color for prev button */
  color: #333333;
  border-radius: 50%;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.slick-prev:hover {
  background-color: #e5534e; /* Darken on hover */
}

.slick-next {
  right: 10px !important;
  z-index: 1;
  background-color: #3d1e81 !important; /* Bright color for next button */
  color: #333333;
  border-radius: 50%;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.slick-next:hover {
  background-color: #e5534e; /* Darken on hover */
}

.home-slide-caption {
  position: absolute;
  bottom: 19px;
  background: rgba(0, 0, 0, 0.5);
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* background-color: #eee; */
  color: #eee;
  padding: 15px;
  text-align: left;
  font-size: 10px;
  line-height: 1.5;
}

/* Custom Dot Navigation */
/* Custom Dot Navigation */
.home-slick-dots {
  position: absolute;
  bottom: 20px !important; /* Adjust to move dots up */
  width: 100%;
  text-align: center;
  z-index: 20; /* Ensure dots are above other elements */
}

.home-slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.home-slick-dots li button:before {
  font-size: 20px !important; /* Adjust size of dots */
  color: black !important; /* Change color of dots to black */
  transition: color 0.3s, font-size 0.3s; /* Smooth transition for color and size changes */
}

.home-slick-dots li.slick-active button:before {
  color: #ff6347 !important; /* Change color for active dot */
  font-size: 24px !important; /* Slightly larger for emphasis */
}

/* Custom Dot Navigation */
.home-slick-dots {
  position: absolute;
  bottom: 20px; /* Adjust to move dots up */
  width: 100%;
  text-align: center;
  z-index: 20; /* Ensure dots are above other elements */
}

.home-slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.home-slick-dots li button:before {
  font-size: 20px; /* Default size of dots */
  color: black; /* Default color of dots */
  transition: color 0.3s, font-size 0.3s; /* Smooth transition for color and size changes */
}

.home-slick-dots li.slick-active button:before {
  color: #ff6347; /* Color for active dot */
  font-size: 24px; /* Larger size for active dot */
}

.home-dot-symbol {
  display: inline-block;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out; /* Smooth transition for scale and color changes */
  animation: pulse 2s infinite; /* Apply pulse animation */
}

.home-dot-symbol:hover {
  transform: scale(1.75);
  color: #333;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home-slide-enter {
  animation: fadeIn 1s ease-in-out;
}

#custom-slick-dot {
  color: #333;
  position: absolute;
  bottom: 0px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

/* Media Queries */
/* Large screens (1280px and below) */
@media (max-width: 1280px) {
  .home-container {
    width: 100vw;
    height: 80vh;
    margin-top: 6.5%;
    margin-bottom: 1%;
  }

  .home-carousel {
    width: 96vw;
    height: 80vh;
  }

  .home-carousel-slide {
    height: 75vh;
  }

  .home-slide-caption {
    bottom: 15px;
    font-size: 12px;
  }

  #custom-slick-dot {
    bottom: -35px;
  }
}

/* Medium screens (1024px and below) */
@media (max-width: 1024px) {
  ::selection {
    background-color: transparent;
  }

  .home-container {
    width: 100vw;
    height: 75vh;
    margin-top: 8%;
    margin-bottom: 1%;
  }

  .home-carousel {
    width: 94vw;
    height: 75vh;
  }

  .home-carousel-slide {
    height: 70vh;
    width: 85%;
  }

  .home-slide-caption {
    bottom: 5px;
    font-size: 8px;
    padding: 10px;
    line-height: 0.5;
  }

  #custom-slick-dot {
    bottom: -20px;
  }
}

/* Tablets (820px and below) */
@media (max-width: 820px) {
  ::selection {
    background-color: transparent;
  }

  .home-container {
    width: 98vw;
    height: 100vh;
    margin-top: 10%;
    margin-bottom: 1%;
    flex-direction: column;
    gap: 1em;
  }

  .home-content-wrapper {
    height: 75%;
  }

  .home-carousel {
    width: 100%;
    height: 60vh;
  }

  .home-carousel-slide {
    height: 50vh;
  }

  .home-carousel-image {
    width: 100%;
    height: 85%;
    object-fit: contain;
  }

  .home-slide-caption {
    bottom: 22%;
    font-size: 9px;
    padding: 10px;
    line-height: 0.5;
  }

  #custom-slick-dot {
    bottom: 0px;
  }

  .home-cpted-india-title,
  .home-objective-title {
    font-size: 2.3em;
    margin-bottom: 10px;
    color: #333333;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .home-cpted-india-text {
    font-size: 1.1rem;
  }

  .home-objective-text {
    font-size: 1.2rem;
  }

  .home-objective-content {
    margin-right: 5%;
  }

  .home-objective-title {
    font-size: 2rem;
  }

  .slick-prev:before,
  .slick-next:before {
    width: 30px;
  }
}

/* Tablets (768px and below) */
@media (max-width: 768px) {
  .home-container {
    width: 99vw;
    height: 110vh;
    margin-top: 11%;
    margin-bottom: 1%;
    flex-direction: column;
    gap: 1em;
  }

  .home-content-wrapper {
    height: 81%;
  }

  .home-carousel {
    width: 100%;
    height: 60vh;
  }

  .home-carousel-slide {
    height: 50vh;
  }

  .home-slide-caption {
    bottom: 15%;
    font-size: 9px;
    padding: 12px;
    line-height: 0.5;
  }

  #custom-slick-dot {
    bottom: -20px;
  }

  .home-cpted-india-title,
  .home-objective-title {
    font-size: 2.3em;
    margin-bottom: 10px;
    color: #333333;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .home-cpted-india-text {
    font-size: 1.1rem;
  }

  .home-objective-text {
    font-size: 1.2rem;
  }

  .home-objective-content {
    margin-right: 5%;
  }

  .home-objective-title {
    font-size: 2rem;
  }

  .home-knowledge-partners-wrapper {
    gap: 4rem;
    animation: slideKnowledgePartners 12s linear infinite;
  }

  @keyframes slideKnowledgePartners {
    0% {
      transform: translateX(35%);
    }
    20% {
      transform: translateX(35%); /* Show the first image */
    }
    50% {
      transform: translateX(-30%); /* Move to the second image */
    }
    60% {
      transform: translateX(-30%); /* Wait before reversing */
    }
    100% {
      transform: translateX(35%); /* Wait before reversing */
    }
  }

  .home-knowledge-partners-title {
    font-size: 1.2rem;
    line-height: 1.1;
  }

  .home-knowledge-partners-image {
    width: 20%;
  }

  .home-knowledge-partners-image-4 {
    width: 35%;
  }

  .home-knowledge-partners-image-5 {
    width: 30%;
  }
}

/* Mobile devices (480px and below) */
@media (max-width: 480px) {
  .home-container {
    width: 100vw;
    height: 50vh;
    margin-top: 14%;
    margin-bottom: 4%;
    flex-direction: column;
    gap: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .home-carousel {
    width: 100%;
    height: 50vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .home-carousel-slide {
    height: 48vh;
  }

  .home-content-wrapper {
    flex-direction: column;
  }

  .home-slide-caption {
    bottom: 13%;
    font-size: 9px;
    padding: 5px;
    line-height: 1.5;
    width: 98%;
  }

  .cptedindia_first_slide_caption {
    bottom: -5%;
    font-size: 8px;
  }

  #custom-slick-dot {
    bottom: 5px;
    right: 25px;
  }

  .home-knowledge-partners-wrapper {
    gap: 2rem;
    animation: slideKnowledgePartners 12s linear infinite;
  }

  @keyframes slideKnowledgePartners {
    0% {
      transform: translateX(42%);
    }
    20% {
      transform: translateX(42%); /* Show the first image */
    }
    50% {
      transform: translateX(-42%); /* Move to the second image */
    }
    60% {
      transform: translateX(-42%); /* Wait before reversing */
    }
    100% {
      transform: translateX(42%); /* Wait before reversing */
    }
  }

  .home-knowledge-partners-title {
    font-size: 1.2rem;
    line-height: 1.1;
  }

  .home-knowledge-partners-image {
    width: 25%;
  }

  .home-knowledge-partners-image-4 {
    width: 40%;
  }

  .home-knowledge-partners-image-5 {
    width: 30%;
  }
}
