.membership-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Arial", sans-serif;
  margin-bottom: 4%;
}

.membership-title {
  font-family: "Merriweather", serif; /* Elegant serif font */
  font-size: 2.5rem;
  color: #6c757d; /* Charcoal Gray for title */
  text-shadow: 1px 1px #d8bfd8; /* Light shadow in Dusty Rose */
  font-style: italic; /* Italic style for a classic look */
  letter-spacing: 1px; /* Slight letter spacing for a refined feel */
  margin-bottom: 20px;
  animation: fadeIn 1s ease-in-out;
}

.membership-logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-left: 20%;
  width: 60%;
}

.membership-logo {
  width: 150px;
  margin: 10px;
  transition: transform 0.3s ease;
}

.membership-logo:hover {
  transform: scale(1.1);
}

.membership-description {
  color: #6c757d; /* Slate Gray */
  font-size: 1.2em;
  line-height: 1.5;
  margin-top: 20px;
  font-family: "Georgia", serif;
  font-style: italic;
}

/* Responsive Styles */
@media (max-width: 1280px) {
  .membership-container {
    margin-bottom: 6%;
  }

  .membership-logo {
    width: 130px;
  }
}

@media (max-width: 1024px) {
  .membership-container {
    margin-bottom: 8%;
  }

  .membership-logo {
    width: 110px;
  }
}

@media (max-width: 820px) {
  .membership-container {
    margin-bottom: 10%;
    height: 57vh;
  }

  .membership-logos {
    flex-direction: row;
    width: 100%;
    margin-left: 0%;
    margin-top: 8%;
  }

  .membership-logo {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .membership-title {
    font-size: 2em;
  }

  .membership-logos {
    flex-direction: row;
    width: 100%;
    margin-left: 0%;
    margin-top: 8%;
  }

  .membership-logo {
    width: 18%;
  }
}

@media (max-width: 480px) {
  .membership-container {
    margin-bottom: 18%;
    height: 73vh;
  }

  .membership-title {
    margin-top: -1%;
    font-size: 1.8em;
  }
  .membership-logo {
    width: 35%;
  }
  .membership-description {
    font-size: 1em;
  }
}

@media (max-width: 360px) {
  .membership-container {
    margin-bottom: 18%;
    height: 86vh;
  }
  .membership-title {
    font-size: 1.6em;
  }
  .membership-logo {
    width: 70px;
  }
  .membership-description {
    font-size: 0.9em;
  }
}
