/* FounderAndPresident.css */

.founder-and-president-container {
  display: flex;
  height: 90vh;
  overflow: hidden;
  font-family: "Arial", sans-serif;
  margin-top: -1%;
  margin-bottom: 3%;
  margin-left: -3%;
  width: 99vw;
}

.slider-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0; /* Stick to the top of the viewport */
  width: 40%;
  background-color: #fff; /* Adjust color as needed */
  /* background-color: #f5f5f0; Adjust color as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.slider-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.about-us-slide-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-container img {
  width: 100%;
  height: auto;
  position: absolute;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  object-fit: cover;
}

.slide-image {
  opacity: 0;
  transform: translateY(100%);
}

.slide-image.active {
  opacity: 1;
  transform: translateY(0);
}

.profile-container {
  width: 60%;
  padding: 40px;
  background-color: #fff; /* Adjust color as needed */
  height: 100vh; /* Full height for the profile container */
  overflow-y: auto; /* Enable vertical scrolling for the profile content */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); /* Add an inner shadow */
  line-height: 1.6; /* Improve readability */
  text-align: justify;
  position: relative;
  transition: background-color 0.3s ease; /* Add transition effect */
}

.profile-container:hover {
  background-color: #fff; /* Slightly lighter color on hover */
}

.profile-container::-webkit-scrollbar {
  width: 5px; /* Thin scrollbar width */
}

.profile-container::-webkit-scrollbar-track {
  background: #fff; /* Track color */
}

.profile-container::-webkit-scrollbar-thumb {
  background: #fff; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for scrollbar thumb */
}

.profile-container::-webkit-scrollbar-thumb:hover {
  background: #c5a1c5; /* Darker thumb color on hover */
}

.profile-container h1 {
  font-size: 2.5em;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  border-bottom: 2px solid #f3e5ab; /* Accent border */
  padding-bottom: 10px;
  transition: color 0.3s ease, border-bottom-color 0.3s ease; /* Transition effect */
}

.profile-container h1:hover {
  color: #d8bfd8; /* Change color on hover */
  border-bottom-color: #d8bfd8; /* Change border color on hover */
}

.profile-container h2 {
  font-size: 1.75em;
  font-weight: semi-bold;
  color: #6c757d;
  margin: 25px 0;
  transition: color 0.3s ease; /* Transition effect */
}

.profile-container h2:hover {
  color: #d8bfd8; /* Change color on hover */
}

.profile-container p {
  font-size: 1em;
  color: #333333;
  margin-bottom: 20px;
  text-align: justify; /* Justify text for better alignment */
  text-indent: 20px; /* Add indentation for paragraphs */
  transition: color 0.3s ease; /* Transition effect */
}

.profile-container p:hover {
  color: #000; /* Change color on hover */
}

.profile-container ul {
  margin: 20px 0;
  padding-left: 40px; /* Indent list items */
}

.profile-container li {
  margin-bottom: 10px;
}

.contact-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15%;
}

.contact-info {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.contact-info p {
  font-size: 1em;
  color: #333333;
  margin-bottom: 10px;
}

.contact-info a {
  color: #8b648b;
  text-decoration: none;
  font-weight: bold;
}

.contact-info a:hover {
  text-decoration: underline; /* Add underline on hover */
  color: #693d69;
}

/* Media Queries */
@media (max-width: 1280px) {
  .contact-info {
    align-items: center;
    margin-bottom: 0%;
    gap: 1rem; /* Adjust gap for contact info */
  }

  .contact-info-wrapper {
    gap: 1rem; /* Adjust gap for contact info wrapper */
    margin-bottom: 20%;
  }
}

/* Tablet devices (max-width: 1024px) */
@media (max-width: 1024px) {
  ::selection {
    background-color: transparent;
  }

  .founder-and-president-container {
    flex-direction: column;
    height: auto;
    padding: 10px; /* Add padding for better spacing */
  }

  .slider-container-wrapper {
    width: 98%;
    height: 75vh;
    position: relative;
    padding: 10px;
    margin-top: 3%;
  }

  .slider-container {
    height: 100%; /* Ensure slider container uses full height */
  }

  .profile-container {
    width: 96%;
    height: auto;
    padding: 20px; /* Adjust padding for tablets */
    margin-top: 20px; /* Add margin to separate sections */
    max-width: 100%; /* Ensure content doesn't overflow */
    overflow-x: hidden; /* Prevent horizontal overflow */
  }

  .profile-container h1 {
    font-size: 2em; /* Adjust font size for h1 */
  }

  .profile-container h2 {
    font-size: 1.5em; /* Adjust font size for h2 */
  }

  .profile-container p {
    font-size: 0.9em; /* Adjust font size for paragraphs */
  }

  .contact-info {
    /* flex-direction: column; */
    align-items: center;
    margin-bottom: 0%;
    gap: 1rem; /* Adjust gap for contact info */
  }

  .contact-info-wrapper {
    /* flex-direction: column; */
    gap: 1rem; /* Adjust gap for contact info wrapper */
    margin-bottom: 0%;
  }
}

@media (max-width: 820px) {
  .founder-and-president-container {
    flex-direction: column;
    height: auto;
    padding: 10px;
    margin-top: -7%;
    width: 96vw;
    margin-bottom: 6%;
  }
}

/* Mobile devices (max-width: 767px) */
@media (max-width: 767px) {
  .founder-and-president-container {
    flex-direction: column;
    height: auto;
    padding: 10px; /* Add padding for better spacing */
  }

  .slider-container-wrapper {
    width: 98%;
    height: 40vh;
    position: relative;
    padding: 10px;
    margin-top: 6%;
  }

  .slider-container {
    height: 100%; /* Ensure slider container uses full height */
  }

  .profile-container {
    width: 100%;
    height: auto;
    padding: 15px; /* Adjust padding for mobiles */
    margin-top: 15px; /* Add margin to separate sections */
    max-width: 100%; /* Ensure content doesn't overflow */
    overflow-x: hidden; /* Prevent horizontal overflow */
  }

  .profile-container h1 {
    font-size: 1.75em; /* Adjust font size for h1 */
  }

  .profile-container h2 {
    font-size: 1.25em; /* Adjust font size for h2 */
  }

  .profile-container p {
    font-size: 0.85em; /* Adjust font size for paragraphs */
  }

  .contact-info {
    flex-direction: column;
    align-items: center;
    gap: 0.8rem; /* Adjust gap for contact info */
  }

  .contact-info-wrapper {
    flex-direction: column;
    gap: 0.8rem; /* Adjust gap for contact info wrapper */
  }
}

/* Smaller mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .founder-and-president-container {
    flex-direction: column;
    height: auto;
    padding: 10px;
    margin-top: -6%;
    margin-bottom: 10%;
  }

  .slider-container-wrapper {
    width: 96%;
    height: 30vh; /* Adjust height for smaller mobiles */
    position: relative; /* Adjust position for smaller mobiles */
    padding: 10px; /* Add padding to prevent overflow */
    margin-top: -4%;
  }

  .slider-container {
    height: 100%; /* Ensure slider container uses full height */
  }

  .profile-container {
    width: 96%;
    height: auto;
    padding: 10px; /* Adjust padding for smaller mobiles */
    margin-top: 10px; /* Add margin to separate sections */
    max-width: 100%; /* Ensure content doesn't overflow */
    overflow-x: hidden; /* Prevent horizontal overflow */
  }

  .profile-container h1 {
    font-size: 1.5em; /* Adjust font size for h1 */
  }

  .profile-container h2 {
    font-size: 1.1em; /* Adjust font size for h2 */
  }

  .profile-container p {
    font-size: 0.8em; /* Adjust font size for paragraphs */
  }

  .contact-info {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 15%;
  }

  .contact-info-wrapper {
    flex-direction: column;
    gap: 0.5rem; /* Adjust gap for contact info wrapper */
  }
}
