.not-found {
  background-color: #fff;
  color: #333333;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1.5s ease-in-out;
}

.not-found .illustration {
  margin-bottom: 20px;
}

.not-found img {
  width: 100%;
  max-width: 400px;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
}

.not-found h1 {
  font-size: 4em;
  color: #d8bf68; /* Pale Gold for the 404 */
  margin-bottom: 20px;
}

.not-found p {
  font-size: 1.2em;
  color: #6c757d; /* Slate Gray text */
  margin-bottom: 10px;
}

.not-found button {
  background-color: #d8bfd8; /* Dusty Rose for button */
  color: #fff; /* Soft Ivory text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.not-found button:hover {
  background-color: #c6a4c6; /* Slightly darker Dusty Rose */
}

/* Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Media Queries */
/* Large screens (1280px and below) */
@media (max-width: 1280px) {
  .not-found h1 {
    font-size: 3.5em;
  }

  .not-found p {
    font-size: 1.1em;
  }

  .not-found button {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

/* Medium screens (1024px and below) */
@media (max-width: 1024px) {
  ::selection {
    background-color: transparent;
  }

  .not-found h1 {
    font-size: 3em;
  }

  .not-found p {
    font-size: 1em;
  }

  .not-found button {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

/* Tablets (820px and below) */
@media (max-width: 820px) {
  .not-found {
    width: 92%;
    height: 78vh;
  }

  .not-found img {
    margin-top: 8%;
    min-width: 95%;
  }

  .not-found h1 {
    font-size: 2.8em;
    margin-top: 10%;
  }

  .not-found p {
    font-size: 1.5em;
    margin-top: 5%;
  }

  .not-found button {
    font-size: 1.25em;
    padding: 8px 14px;
    margin-top: 5%;
  }
}

/* Tablets (768px and below) */
@media (max-width: 768px) {
  .not-found {
    width: 90%;
    height: 77vh;
  }

  .not-found img {
    margin-top: 8%;
    min-width: 95%;
  }

  .not-found h1 {
    font-size: 2.5em;
    margin-top: 7%;
  }

  .not-found p {
    font-size: 1.5em;
    margin-top: 5%;
  }

  .not-found button {
    font-size: 1.35em;
    padding: 8px 14px;
    margin-top: 4%;
  }
}

/* Mobile devices (480px and below) */
@media (max-width: 480px) {
  .not-found {
    width: 81%;
    height: 74vh;
    margin-top: 17%;
  }

  .not-found img {
    margin-top: 25%;
    min-width: 90%;
  }

  .not-found h1 {
    font-size: 2.5em;
    margin-top: 10%;
  }

  .not-found p {
    font-size: 1.25em;
    margin-top: 10%;
  }

  .not-found button {
    font-size: 1.35em;
    padding: 10px 14px;
    margin-top: 9%;
  }
}
